<template>
  <div>
    <v-dialog
      v-model="availabilityDialog"
      max-width="600px"
      :fullscreen="xs"
    >
      <global-card
        :title="(editMode) ? t('smpw.edit_availability') : t('smpw.submit_availability')"
        closable
        @close="cancel()"
      >
        <loading-spinner
          v-if="(savingCount > 0) || (loadingCount > 0)"
          :message="t('states.loading')"
        />
        <v-alert
          v-if="savingError"
          type="error"
          :text="t('errors.failed_to_save_availability', { reason: savingError })"
        />
        <v-alert
          v-if="loadingError"
          type="error"
          :text="t('errors.failed_to_load_availability', { reason: loadingError })"
        />

        <div v-if="selectedDate">
          <p
            v-if="!loadingCount"
            class="text-body-1"
          >
            <strong>{{ toLongDateWithDay(selectedDate) }}</strong>
          </p>
          <form
            v-if="canSave"
            @submit.stop.prevent="handleSubmit()"
          >
            <v-alert
              v-if="assignedShiftsThisWeek >= user.shifts_per_week"
              type="warning"
            >
              {{ t('shift.already_assigned') }} {{ assignedShiftsThisWeek }}
              {{ t('shift.title', assignedShiftsThisWeek) }}
              {{ t('shift.already_assigned_2') }}
              <span v-if="maxShiftsPerWeek > props.user.shifts_per_week">
                {{ `${t('shift.already_assigned_3')}
            ${assignedShiftsThisWeek} ${t('shift.title', assignedShiftsThisWeek)} ${t('shift.already_assigned_4')}` }}
              </span>
            </v-alert>
            <div v-if="anyTimeSlotsAvailable">
              <div
                class="mt-3"
              >
                <radio-button-group
                  v-model="lastMinute"
                  :label="t('shift.can_cover_last_minute')"
                  inline
                  :error="lastMinuteValid"
                  :error-messages="lastMinuteValid ? [lastMinuteInvalidMessage] : undefined"
                  :items="[{
                    text: t('global.yes'),
                    value: true,
                  }, {
                    text: t('global.no'),
                    value: false,
                  }]"
                />
                <v-divider />
                <radio-button-group
                  v-model="shiftsPerDay"
                  :label="t('shift.how_many')"
                  inline
                  :items="shiftsPerDayOptions"
                />

                <v-alert
                  v-if="assignedShiftsThisDate >= shiftsPerDay"
                  type="warning"
                  class="text-subtitle-1"
                >
                  {{ t('shift.already_assigned') }}
                  {{
                    assignedShiftsThisDate }}
                  {{ t('shift.title', assignedShiftsThisDate) }}
                  {{ t('shift.already_assigned_5') }}
                  <span v-if="maxShiftsPerDay > shiftsPerDay" />
                  {{ t('shift.already_assigned_3') }}
                  {{ assignedShiftsThisDate }}
                  {{ t('shift.title', assignedShiftsThisDate) }}
                  {{ t('shift.already_assigned_5') }}
                </v-alert>
                <v-divider />
                <div
                  v-if="shiftTags.length > 0"
                  class="my-2"
                >
                  <span class="text-subtitle-1">{{ t('shift.tagged_as') }} </span>
                  <p
                    v-for="(tag, index) in uniqueShiftTags"
                    :key="index"
                    class="my-1"
                  >
                    <shift-tag
                      :shift-tag="tag"
                      size="large"
                    />
                  </p>
                  <v-divider />
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>
                        <div class="float-left mt-3">
                          {{ t('date.time', 2) }}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(slot, index) in availableSlotOptions"
                      :key="index"
                    >
                      <th>
                        <v-checkbox
                          v-model="selectedTimeBands"
                          :value="slot.value"
                          :error="selectedTimeBands.length === 0 && formDirty"
                          :disabled="slot.disabled"
                          @update:model-value="formDirty = true"
                        >
                          <template #label>
                            <span class="me-2">{{ slot.label }} - {{ slot.band.name }}</span>
                          </template>
                        </v-checkbox>
                      </th>

                      <th class="float-left">
                        <shift-tag
                          v-for="(tag, i) in slot.tags"
                          :key="i"
                          size="small"
                          :shift-tag="tag"
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-if="selectedTimeBands.length === 0 && formDirty"
                  class="text-error mt-2"
                >
                  {{ t('shift.select_one_slot') }}
                </div>
                <table v-if="getConfig('smpw.allow_availability_location_selection') && selectedTimeBands.length > 0">
                  <thead>
                    <tr>
                      <th>
                        <div class="float-left mt-3">
                          {{ t('location.title', 2) }}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <v-checkbox
                          v-if="availableLocations.length > 2"
                          v-model="allLocationsSelected"
                          :indeterminate="someLocationsSelected"
                          :error="!selectedLocationsValid"
                          @change="toggleAllLocations"
                        >
                          <template #label>
                            {{ allLocationsSelected ? t('location.deselect_all') : t('location.select') }}
                          </template>
                        </v-checkbox>
                      </th>
                    </tr>

                    <tr
                      v-for="(location, index) in availableLocationOptions"
                      :key="index"
                    >
                      <th>
                        <v-checkbox
                          v-model="selectedLocations"
                          :error="!selectedLocationsValid || selectedLocations.length === 0"
                          :value="location.id"
                          :disabled="location.disabled"
                        >
                          <template #label>
                            <span class="me-2 text-left">{{ location.name }}</span>
                          </template>
                        </v-checkbox>
                      </th>
                      <th>
                        <shift-tag
                          v-for="(tag, i) in location.tags"
                          :key="i"
                          size="small"
                          :shift-tag="tag"
                        />
                      </th>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-if="selectedTimeBands.length && (!selectedLocationsValid || selectedLocations.length === 0)"
                  class="text-error mt-2"
                >
                  {{ t('location.at_least_one') }}
                </div>

                <v-select
                  v-model="recurringWeeks"
                  :label="t('shift.apply_to')"
                  class="mt-5"
                  :items="recurringOptions"
                  item-title="text"
                  item-value="value"
                />
              </div>
            </div>
            <v-alert
              v-else
              type="warning"
              class="mt-3"
              :text="t('shift.no_slots_available')"
            />
          </form>
        </div>
        <v-alert
          v-else
          type="error"
          class="mt-3"
          :text="t('shift.no_date_selected')"
        />

        <template #actions>
          <v-btn
            v-if="editMode && anyTimeSlotsAvailable"
            color="error"
            :disabled="!canSave"
            :text="t('actions.delete')"
            @click="deleteAvailability = true"
          />
          <v-btn
            :text="t('actions.close')"
            color="dark-grey"
            @click="cancel()"
          />
          <v-btn
            v-if="anyTimeSlotsAvailable && selectedDate"
            color="primary"
            :disabled="!canSave || !selectedLocationsValid || !selectedTimeBandsValid"
            :text="editMode ? t('actions.update') : t('actions.request')"
            @click="handleSubmit(false)"
          />
          <v-btn
            v-if="!editMode && anyTimeSlotsAvailable && selectedDate && canBookForSpouse"
            color="primary"
            :text="t('actions.request_spouse')"
            :disabled="!canSave"
            @click="handleSubmit(true)"
          />
        </template>
      </global-card>
    </v-dialog>
    <delete-availability-dialog
      v-model="deleteAvailability"
      :date="selectedDate"
      :user="user"
      :edit-mode="editMode"
      @availability:deleted="onAvailabilityDeleted"
    />
  </div>
</template>

<script setup lang="ts">
import {
  useAuth,
  useConfig,
  useDate,
  useSettings,
} from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import {
  computed,
  ref,
  watch,
} from 'vue';
// @ts-nocheck
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import ShiftTag from '../ShiftTag.vue';
import DeleteAvailabilityDialog from './DeleteAvailabilityDialog.vue';

const props = withDefaults(defineProps<{
  user: App.Models.User;
  dontCheckTraining?: boolean;
  preSelectedSlots?: Array<PreselectedSlot>;
}>(), {
  dontCheckTraining: () => false,
  preSelectedSlots: () => [],
});

const emit = defineEmits(['availability:updated']);

const { t } = useI18n();

const { xs } = useDisplay();
const formDirty = ref(false);
const availabilityDialog = ref(false);
const deleteAvailability = ref(false);
const { showSnackMessage } = useErrorStore();

const {
  addToDate,
  toUniversalDate,
  startOf,
  toShortTime,
  isSameOrBefore,
  isSameOrAfter,
  getAsDate,
  planningDates,
  getAsValidDateOrNull,
  toLongDateWithDay,
} = useDate();
const { authUser } = useAuth();
const { getSetting } = useSettings();
const { getConfig } = useConfig();

const assignedShiftsThisDate = ref(0);
const assignedShiftsThisWeek = ref(0);
const selectedDate = ref(null);
const shiftTags = ref([]);
const availableSlots = ref([]);
const availabilityResponse = ref([]);
const selectedTimeBands = ref([]);
const selectedTimeBandsValid = computed(() => selectedTimeBands.value?.length > 0);
const assignedLocations = ref([]);
const availableLocations = ref([]);
const selectedLocations = ref([]);
const selectedLocationsValid = computed(() => selectedLocations.value?.length > 0);
const lastMinute = ref(true);
const shiftsPerDay = ref(1);
const allLocationsSelected = ref(false);
const someLocationsSelected = ref(false);
const loadingCount = ref(0);
const loadingError = ref<any>(null);
const savingCount = ref(0);
const savingError = ref<any>(null);
const recurringWeeks = ref(0);

const lastMinuteInvalidMessage = t('shift.last_minute_warning');
const editMode = computed(() => {
  if (!availabilityResponse.value.length) {
    return false;
  }
  return availabilityResponse.value.filter((shift) => {
    return shift.status === 'available';
  });
});
function onAvailabilityDeleted() {
  availabilityDialog.value = false;
  emit('availability:updated', selectedDate.value);
}

const maxShiftsPerDay = computed(() => Number.parseInt(getSetting('max_shifts_per_day'), 10));
const maxShiftsPerWeek = computed(() => Number.parseInt(getSetting('max_shifts_per_week'), 10));
const canSave = computed(() => (loadingCount.value === 0) && (savingCount.value === 0) && !loadingError.value);
const recurringOptions = computed(() => {
  const recurrantOptions = [
    { value: 0, text: t('date.just_this_week') },
  ];
  const times = Number.parseInt(getConfig('smpw.planning_weeks'), 10);
  const maxDate = getAsDate(planningDates().end);
  const fromDate = planningDates().start;
  const from: Date = fromDate > selectedDate.value ? fromDate : selectedDate.value;
  let endDate: any = null;
  for (let i = 1; i < times; i += 1) {
    /* @ts-ignore */
    endDate = addToDate(from, i, 'weeks');
    /* @ts-ignore */
    if (endDate < maxDate) {
      recurrantOptions.push({
        /* @ts-ignore */
        value: i,
        text: t('shift.available.recurring_option', { n: i, word_for_weeks: t('date.week', i), end_date: endDate.format('dddd, MMMM Do') }),
      });
    }
  }
  return recurrantOptions;
});

const isSelectedDateLastMinute = computed(() => {
  const today = startOf(undefined);
  const shiftDate = startOf(selectedDate.value);
  const diffDays = shiftDate.diff(today, 'days');
  const lastMinuteDays = Number.parseInt(getConfig('smpw.last_minute_days'), 10);
  return (diffDays < lastMinuteDays);
});

const lastMinuteValid = computed(() => !lastMinute.value && isSelectedDateLastMinute.value);

function didSelectionChange(newSelection, oldSelection) {
  const newValue = newSelection.slice(0).sort();
  const oldValue = oldSelection.slice(0).sort();
  return !((newValue.length === oldValue.length) && newValue.every((value, index) => value === oldValue[index]));
}

function toggleAllLocations() {
  if (allLocationsSelected.value) {
    selectedLocations.value = availableLocations.value.map((location) => location.id);
  }
  else {
    selectedLocations.value = assignedLocations.value;
  }
}

function isLocationAssigned(locationId) {
  return assignedLocations.value.includes(locationId);
}
function getShiftTagsForLocation(locationId) {
  const uniqueTags = new Set(shiftTags.value.filter((b) => b.location_id === locationId).map((a) => a.id));
  return Array.from(uniqueTags).map((tid) => shiftTags.value.find((a) => a.id === tid));
}
function getShiftTagsForTime(begins) {
  const uniqueTags = new Set(shiftTags.value.filter((a) => a.begins === begins).map((a) => a.id));
  return Array.from(uniqueTags).map((tid) => shiftTags.value.find((a) => a.id === tid));
}

const assignedTimeBands = computed(() => {
  const returnBands = [];
  const shifts = availabilityResponse.value.filter((shift) => shift.pivot?.status === 'assigned');
  shifts.forEach((shift) => {
    returnBands.push(shift.shift_time_band_id);
  });
  return returnBands;
});

function isTimeBandAssigned(timeBandId) {
  return assignedTimeBands.value.includes(timeBandId);
}

// eslint-disable-next-line unicorn/no-new-array
const shiftsPerDayOptions = computed(() => Array.from(new Array(maxShiftsPerDay.value)).map((x, i) => ({ text: (i + 1), value: (i + 1) })));
const anyTimeSlotsAvailable = computed(() => availableSlots.value.find((slot) => !slot.disabled) !== undefined);
const canBookForSpouse = computed(() => props.user.spouse?.active && props.user.spouse?.training_completed);
const availableSlotOptions = computed(() => availableSlots.value.map((slot) => {
  slot.value = slot.shift_time_band_id;
  slot.disabled = (isTimeBandAssigned(slot.shift_time_band_id) || isSameOrBefore(`${toUniversalDate(selectedDate.value)} ${slot.begins}`));
  slot.tags = getShiftTagsForTime(slot.begins);
  slot.label = `${toShortTime(slot.begins)} - ${toShortTime(slot.ends)}`;
  return slot;
}));

interface LocationOptionPivot {
  created_at: string;
  location_id: number;
  shift_plan_slot_id: number;
  updated_at: string;
}
interface LocationOption {
  id: number;
  name: string;
  code: string;
  publishers_per_shift: number;
  min_brothers: number;
  details: string;
  store_id: number;
  active: boolean;
  created_at: string;
  created_by: null;
  updated_at: string;
  updated_by: null;
  hero_image_id: null;
  pivot: LocationOptionPivot;
  disabled: boolean;
  tags: Array<any>;
}

type LocationOptions = Array<LocationOption>;

const availableLocationOptions = computed<LocationOptions>(() => {
  const tempLocations = availableLocations.value;

  return tempLocations.sort((a, b) => a.name.localeCompare(b.name)).map((location) => {
    location.tags = getShiftTagsForLocation(location.id);
    return location;
  });
});

/*
  Get unique shift tags from the shiftTags collection
  otherwise we end up displaying every permutation of
  location, slots and tags.
*/
const uniqueShiftTags = computed(() => {
  const uniqueTags = {};
  shiftTags.value.forEach((tag) => uniqueTags[tag.id] = tag);
  return uniqueTags;
});

async function loadShiftTags(date) {
  try {
    const response = await axios.get(`/api/shifts/tags?date=${toUniversalDate(date)}`);
    shiftTags.value = response.data.data;
  }
  catch (error) {
    showSnackMessage(t('shift_tag.cannot_load'));
  }
}

function selectDefaultLocations() {
  // Selecting default locations, all except unwanted ones
  selectedLocations.value = availableLocations.value.filter((location) => (
    !getConfig('smpw.allow_availability_location_selection')
    /* @ts-ignore */
    || (props.user?.unwanted_locations.findIndex((unwantedLocation) => unwantedLocation.id === location.id) === -1)
  )).map((location) => location.id);
}

function updateSelectionAccordingToAvailability() {
  // Removing selected time slots that are not available anymore
  selectedTimeBands.value = selectedTimeBands.value.filter(
    (band) => (availableSlots.value.findIndex((availableSlot) => availableSlot.band.id === band) !== -1),
  );
  // Removing selected locations that are not available anymore
  selectedLocations.value = selectedLocations.value.filter(
    (location) => (availableLocations.value.findIndex((availableLocation) => availableLocation.id === location) !== -1),
  );
}

function updateAvailableLocations(updateSelection) {
  // Remembering the currently available locations
  const oldAvailableLocations = availableLocations.value.map((location) => location.id);
  // Updating available locations
  availableLocations.value = [];
  availableSlots.value.forEach((slot) => {
    if (selectedTimeBands.value.includes(slot.band.id)) {
      slot.locations.forEach((slotLocation) => {
        if (availableLocations.value.findIndex((availableLocation) => availableLocation.id === slotLocation.id) === -1) {
          slotLocation.disabled = isLocationAssigned(slotLocation.id);
          availableLocations.value.push(slotLocation);
        }
      });
    }
  });
  // If we want to allow selection to be updated ...
  if (updateSelection) {
    // We only need to update the selection of the locations if
    // the available locations have actually chaged
    const newAvailableLocations = availableLocations.value.map((location) => location.id);
    if (didSelectionChange(newAvailableLocations, oldAvailableLocations)) {
      // Removing selected options that are not available anymore
      updateSelectionAccordingToAvailability();
      // Default location selection when no locations are selected
      if (props.preSelectedSlots.length === 0) {
        selectDefaultLocations();
      }
    }
  }
}

function cancel() {
  availabilityDialog.value = false;
  formDirty.value = false;
}

function checkFormValidity() {
  // Removing selected options that are not available anymore
  updateSelectionAccordingToAvailability();
  return (selectedTimeBandsValid.value && selectedLocationsValid.value);
}

async function handleSubmit(includeSpouse = false) {
  // If the form is valid ...
  if (checkFormValidity()) {
    // Attempt to save
    savingError.value = null;
    savingCount.value += 1;
    try {
      await axios.post(`/api/users/${props.user.id}/shifts/availability`, {
        avail_user: props.user.id,
        avail_date: toUniversalDate(selectedDate.value),
        avail_last_minute: lastMinute.value,
        avail_shifts_per_day: shiftsPerDay.value,
        avail_slots: availableSlots.value.filter((slot) => selectedTimeBands.value.includes(slot.band.id)).map((slot) => slot.id),
        avail_locations: selectedLocations.value,
        incl_spouse: includeSpouse,
        recurring_weeks: recurringWeeks.value,
      });
      savingCount.value -= 1;
      availabilityDialog.value = false;
      formDirty.value = false;
      showSnackMessage(editMode.value ? t('smpw.availability_updated') : t('smpw.availability_submitted'), 'success');
      emit('availability:updated', selectedDate.value);
    }
    catch (error) {
      savingError.value = error.response.data.message;
      savingCount.value -= 1;
    }
  }
}

function handlePreSelectedSlot() {
  if (props.preSelectedSlots.length > 0) {
    props.preSelectedSlots.forEach((el) => {
      const shiftTimeBandId = el.shift_time_band_id;
      const locationId = el.location_id;
      if (!selectedTimeBands.value.includes(shiftTimeBandId)) {
        selectedTimeBands.value.push(shiftTimeBandId);
      }
      if (!selectedLocations.value.includes(locationId)) {
        selectedLocations.value.push(locationId);
      }
    });
  }
}

async function loadAvailability(date) {
  loadingCount.value += 1;
  try {
    const slotsResponse = await axios.get(`/api/shifts/plan/day/${toUniversalDate(date)}`);
    availableSlots.value = slotsResponse.data.data;
    loadingCount.value -= 1;

    loadingCount.value += 1;
    const response = await axios.get(`/api/users/${props.user.id}/shifts/availability?date=${toUniversalDate(date)}`);
    // Updating available time slots from response

    // Updating assigned shift counts
    assignedShiftsThisDate.value = response.data.assigned_shifts_this_day;
    assignedShiftsThisWeek.value = response.data.assigned_shifts_this_week;
    availabilityResponse.value = response.data.shifts;

    // Updating selected time slots
    selectedTimeBands.value = availabilityResponse.value
      .filter((shift) => !isSameOrAfter(`${selectedDate.value} ${shift.begins}`))
      .map((shift) => shift.band.id)
      .filter((value, index, self) => self.indexOf(value) === index);

    // Updating selected locations
    selectedLocations.value = availabilityResponse.value
      .map((shift) => shift.location?.id)
      .filter((value, index, self) => self.indexOf(value) === index);
    assignedLocations.value = availabilityResponse.value
      .filter((shift) => shift.pivot.status === 'assigned')
      .map((shift) => shift.location?.id)
      .filter((value, index, self) => self.indexOf(value) === index);

    // Updating selected options
    const firstAvailableShift = availabilityResponse.value.find((shift) => shift.pivot?.status === 'available');
    shiftsPerDay.value = response.data.shifts_per_day;
    lastMinute.value = response.data.available_last_minute;

    // Updating list of available locations
    updateAvailableLocations(false);

    // Applying pre selected slots
    handlePreSelectedSlot();
  }
  catch (error) {
    console.error(error);
    // No assigned shift loaded
    assignedShiftsThisDate.value = 0;
    assignedShiftsThisWeek.value = 0;

    // No time slots could be loaded
    availableSlots.value = [];

    // No selection loaded
    selectedTimeBands.value = [];
    selectedLocations.value = [];
    assignedLocations.value = [];
    lastMinute.value = true;
    shiftsPerDay.value = 1;

    // Updating list of available locations
    updateAvailableLocations(false);
    // Finished loading data
  }
  finally {
    loadingCount.value = 0;
  }
}

function updateAvailability(date) {
  const validDateOrNull = getAsValidDateOrNull(date);
  const trainingOk = props.user.training_completed || props.dontCheckTraining;
  const pictureOk = props.user.profile_image_status !== 'rejected';
  const adj = (props.user.gender === 'b') ? t('global.his') : t('global.her');
  if (!pictureOk) {
    let msg = t('smpw.submitting_availability_0', { name: props.user.full_name, his_her: adj });
    msg += t('smpw.submitting_availability_1');
    if (authUser.id === props.user.id) {
      msg = t('smpw.submitting_availability_2');
    }
    showSnackMessage(msg, 'success');
  }
  else if (!trainingOk) {
    let msg = t('smpw.submitting_availability_0', { name: props.user.full_name, his_her: adj });
    msg += t('smpw.submitting_availability_3');
    if (authUser.id === props.user.id) {
      msg += ` ${t('smpw.submitting_availability_4')}`;
    }
    showSnackMessage(msg, 'warning');
  }
  else {
    selectedDate.value = validDateOrNull;
    if (date) {
      loadAvailability(date);
      loadShiftTags(date);
    }
    availabilityDialog.value = true;
  }
}

watch(selectedTimeBands, async (newValue, oldValue) => {
  if (!loadingCount.value && (didSelectionChange(newValue, oldValue))) {
    updateAvailableLocations(true);
  }
});

watch(selectedLocations, async (newValue, oldValue) => {
  if (didSelectionChange(newValue, oldValue)) {
    if (newValue.length === 0) {
      someLocationsSelected.value = false;
      allLocationsSelected.value = false;
    }
    else if (newValue.length === availableLocations.value.length) {
      someLocationsSelected.value = false;
      allLocationsSelected.value = true;
    }
    else {
      someLocationsSelected.value = true;
      allLocationsSelected.value = false;
    }
  }
});

defineExpose({ updateAvailability });
</script>
