<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    location="start"
  >
    <template #activator="{ props: activatorProps }">
      <v-text-field
        :model-value="displayValue"
        v-bind="activatorProps"
        class="mb-4"
        readonly
        prepend-icon="fas fa-calendar"
        :label="label"
        :error-messages="errorMessages"
        :error="error"
        :hint="hint"
        hide-details
        :persistent-hint="persistentHint"
        :disabled="disabled"
      >
        <template #append>
          <v-icon
            v-if="clearable"
            icon="fas fa-window-close"
            @click.stop="date = null"
          />
          <slot name="text-append" />
        </template>
      </v-text-field>
    </template>

    <v-card width="auto">
      <v-date-picker
        :min="min"
        :max="max"
        :color="color"
        show-adjacent-months
        :name="name"
        :disabled="disabled"
        :model-value="mv"
        @update:model-value="updateValue"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :text="t('global.ok')"
          color="primary"
          @click="menu = false"
        />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = withDefaults(defineProps<{
  label?: string;
  error?: boolean;
  errorMessages?: any;
  format?: string;
  persistentHint?: boolean;
  hint?: string;
  color?: string;
  name?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  clearable?: boolean;
}>(), {
  label: () => useI18n().t('date.select'),
  color: () => 'primary',
  disabled: () => false,
  clearable: () => true,
});
const { t } = useI18n();
const { formatDate, getAsDate, toLongDateWithShortDay } = useDate();

const date = defineModel<string | Date | null>();

const mv = computed(() => getAsDate(date.value));

function updateValue(value) {
  if (value) {
    date.value = formatDate(value, props.format);
  }
  return undefined;
}
const displayValue = computed(() => {
  if (!date.value) {
    return '';
  }
  return toLongDateWithShortDay(date.value);
});

const menu = ref(false);
</script>
