<template>
  <article class="mb-3">
    <global-card
      :heading-color="color"
      :heading-text-color="headingTextColor"
    >
      <template
        #title
      >
        <div
          class="text-left text-wrap"
        >
          <v-icon
            :icon="`fas fa-${article.icon}`"
            class="me-2 mt-n1"
            size="sm"
          />
          <span>
            {{ article.title }}
          </span>
        </div>
      </template>
      <p
        class="tiptap text-font mt-2"
        v-html="article.message"
      />
      <template #actions>
        <v-spacer />
        <div class="text-font">
          {{ toLongDateWithDay(article?.publication_date ?? undefined) }}
        </div>
      </template>
    </global-card>
  </article>
</template>

<script setup lang="ts">
import { useDate } from '@/composables';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = withDefaults(defineProps<{
  article: App.Models.News;
  featured?: boolean;
}>(), {
  featured: false,
});
const { t } = useI18n();
const { toLongDateWithDay } = useDate();

const color = computed(() => (props.featured ? 'primary' : 'primary-lighten-1'));
const headingTextColor = computed(() => (props.featured ? 'white' : 'primary-darken-1'));
</script>
