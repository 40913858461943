<template>
  <div
    v-if="location"
    class="mb-4"
  >
    <smpw-map
      ref="googleMapComponent"
      :center="mapCenter"
      @center_changed="updateCenter"
    >
      <smpw-map-marker
        v-for="(m, index) in markersWithNumbers"
        :key="index"
        :title="m.marker.description"
        :position="m.marker.position"
        :marker-image-url="markerImage(m.marker.type)"
        :read-only="false"
        @update:position="updateLocation($event, m.marker)"
      />
    </smpw-map>

    <!-- Location Markers -->
    <div v-if="markerTypes">
      <div
        v-for="(markerType, markerTypeIdent) in markerTypes"
        :key="markerTypeIdent"
        class="my-2"
      >
        <v-card
          v-if="!readOnly || (getMarkersByType(markerTypeIdent).length > 0)"
          class="mb-4"
        >
          <v-toolbar
            :color="getColorFromMarkerType(markerTypeIdent)"
            class="mb-3"
          >
            <v-icon
              :icon="`fas ${markerType.icon}`"
              class="ms-3 me-4"
            />
            <span>{{ markerType.name }}</span>
            <v-spacer />
            <v-card-actions v-if="!readOnly">
              <v-btn
                color="primary"
                :text="`${t('states.new')} ${markerType.name}`"
                @click="addNewMarker(markerType.name, markerTypeIdent)"
              />
            </v-card-actions>
          </v-toolbar>

          <div
            v-for="(marker, index) in getMarkersByType(markerTypeIdent)"
            :key="index"
            class="ms-2"
          >
            <v-row>
              <v-col>
                <template v-if="readOnly">
                  <div
                    class="text-body-1 d-flex"
                  >
                    <v-img
                      :width="40"
                      :height="40"
                      inline
                      :src="`/images/mapmarkers/pin_${markerTypeIdent}_${(index + 1)}.png`"
                    />
                    <div>
                      <strong class="ms-2">{{ markerType.name }} {{ (markerType.max > 1) ? `#${(index + 1)}` : '' }}</strong>
                      <p class="ms-8">
                        {{ marker.description }}
                      </p>
                    </div>
                  </div>
                </template>
                <div
                  v-else
                  class="d-flex flex-row"
                >
                  <v-img
                    :width="40"
                    :height="40"
                    inline
                    class="mt-1"
                    :src="`/images/mapmarkers/pin_${markerTypeIdent}_${(index + 1)}.png`"
                  />
                  <v-text-field
                    v-model="marker.description"
                    class="mt-1"
                    :label="`${markerType.name} ${(markerType.max > 1) ? `#${(index + 1)}` : ''} ${t('global.description')}:`"
                    hide-details
                  >
                    <template #append>
                      <localizer-dialog
                        v-if="!readOnly"
                        class="mr-1"
                        @update:location="updateLocationFromLocalizer($event, marker)"
                      />
                      <image-selector
                        v-model="marker.images"
                        class="mr-1"
                        :images="location.images"
                      />
                      <v-btn
                        v-if="!readOnly"
                        color="error"
                        class="me-2"
                        @click="deleteItem(marker.uid)"
                      >
                        <v-icon icon="fas fa-trash" />
                      </v-btn>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
              <v-col
                v-if="readOnly"
                class="mt-3"
              >
                <v-btn
                  color="primary"
                  :href="getDirectionsUrl(marker.position?.lat, marker.position?.lng)"
                  target="_blank"
                  prepend-icon="fas fa-compass"
                  :text="t('global.directions')"
                  class="me-2 float-right"
                />
                <image-slideshow
                  v-if="marker.images && marker.images.length > 0"
                  :images="marker.images?.map((image) => ({ ...image, href: image.signed_file_url }))"
                  as-dialog
                />
              </v-col>
            </v-row>
          </div>
          <div class="mb-2" />
        </v-card>
      </div>
    </div>

    <v-card v-if="store">
      <v-toolbar
        color="primary"
      >
        <v-icon
          icon="fas fa-warehouse"
          class="mx-5"
        />
        <span>{{ t('store.title') }}</span>
      </v-toolbar>
      <v-row class="my-2 mb-2">
        <v-col cols="1">
          <v-img
            src="/images/mapmarkers/pin_store.png"
            max-height="30px"
          />
        </v-col>
        <v-col>
          <p><strong>{{ store.name }}</strong></p>
        </v-col>
        <v-col>
          <v-btn
            :href="`https://www.google.com/maps/dir/?api=1&destination=${store.lat},${store.lng}`"
            target="_blank"
            prepend-icon="fas fa-compass"
            :text="t('global.directions')"
            color="primary"
            class="me-2 float-right"
            :class="smAndDown ? 'mt-2' : ''"
          />
          <v-btn
            :text="t('location.more_info')"
            color="primary"
            class="me-2 float-right"
            :class="smAndDown ? 'mt-2' : ''"
            @click="emit('change:tab', 'store_info')"
          />
          <image-slideshow
            v-if="store.images && store.images.length > 0"
            :as-dialog="true"
            button-color="primary"
            :images="store.images.map((image) => ({ href: image.signed_file_url }))"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import SmpwMap from '@/components/globals/SmpwMap.vue';
import SmpwMapMarker from '@/components/globals/SmpwMapMarker.vue';
import { useConfig } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import debounce from 'lodash/debounce';
import {
  computed,
  onMounted,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import LocalizerDialog from '../dialogs/LocalizerDialog.vue';
import ImageSlideshow from '../images/ImageSlideshow.vue';
import ImageSelector from './ImageSelector.vue';

// Need to set  store correctly.
const props = defineProps<{
  name?: string;
  store?: App.Models.Store;
  readOnly?: boolean;
}>();

const emit = defineEmits(['change:tab']);

const mapsKey = window.MAPS_API_KEY;
const { t } = useI18n();

const { showSnackMessage } = useErrorStore();
const { getConfig } = useConfig();

const { xs, smAndDown } = useDisplay();
const tab = ref('info');
const location = defineModel<App.Models.Location>();
const markerTypes = ref<Array<MarkerType>>([]);

function updateLocation(e, marker: App.Models.LocationMarker) {
  marker.lat = e.latLng.lat();
  marker.lng = e.latLng.lng();
  marker.position = {
    lat: e.latLng.lat(),
    lng: e.latLng.lng(),
  };
  executeAutoZoom();
}

function getImage(type, number) {
  const img = document.createElement('img');
  img.src = `/images/mapmarkers/pin_${type}_${number}.png`;
  return img;
}

const googleMapComponent = ref<any>();

const mapCenter = ref({
  lat: getConfig('smpw.city_centre_lat'),
  lng: getConfig('smpw.city_centre_lng'),
});

async function executeAutoZoom() {
  await new Promise(r => setTimeout(r, 500));
  if (!googleMapComponent.value) {
    return;
  }
  // @ts-ignore
  const bounds = new google.maps.LatLngBounds();

  let boundsSet = false;
  if (props.store) {
    /* @ts-ignore */
    bounds.extend({ lat: Number.parseFloat(props.store.lat), lng: Number.parseFloat(props.store.lng) });
    boundsSet = true;
  }
  location.value?.markers?.filter((marker) => !marker.deleted).forEach((m) => {
    bounds.extend(m.position);
    boundsSet = true;
  });
  if (boundsSet) {
    googleMapComponent.value.mapRef.map.fitBounds(bounds);
  }
}

function getMarkersByType(markerType) {
  if (!location.value || !location.value.markers) {
    return [];
  }
  return location.value.markers.filter((marker) => (marker.type === markerType) && !marker.deleted).sort((a, b) => a.order - b.order);
}

const markersWithNumbers = computed<any>(() => {
  const sortedMarkers: Array<any> = [];
  Object.keys(markerTypes.value).forEach((markerType) => {
    getMarkersByType(markerType).forEach((marker, index) => {
      sortedMarkers.push({
        marker,
        number: (index + 1),
      });
    });
  });
  return sortedMarkers;
});

function getUniqueMarkerUid(): number {
  if (location.value?.markers?.length === 0) {
    return 1;
  }

  const maxUid = location.value?.markers?.filter((m) => !Number.isNaN(m.uid)).map((m) => m.uid ?? 0);

  /* @ts-ignore */
  return Math.max(0, ...maxUid) + 1;
}

onMounted(() => {
  location.value?.markers?.forEach((marker) => {
    marker.uid = getUniqueMarkerUid();
    marker.deleted = marker.deleted ?? false;
  });
  executeAutoZoom();
});

function updateMarkerOrderValues() {
  Object.keys(markerTypes.value).forEach((markerType) => {
    getMarkersByType(markerType).forEach((marker, index) => {
      marker.order = index;
    });
  });
}
function getDirectionsUrl(lat, lng) {
  return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
}

const updateCenter = debounce((e) => {
  if (e) {
    mapCenter.value = e;
  }
}, 500);

function updateLocationFromLocalizer(e, marker) {
  marker.position = e;
  executeAutoZoom();
}

function addNewMarker(markerTypeName, markerTypeIdent) {
  if (!location.value) {
    return;
  }
  if (!location.value?.markers) {
    location.value.markers = [];
  }
  const markerCount = getMarkersByType(markerTypeIdent).length;
  const maxMarkerCount = markerTypes.value[markerTypeIdent].max;
  if (markerCount < maxMarkerCount) {
    /* @ts-ignore */
    location.value?.markers.push({
      id: null,
      uid: getUniqueMarkerUid(),
      type: markerTypeIdent,
      images: [],
      description: `${markerTypeName} ${markerCount + 1}`,
      position: { lat: mapCenter.value.lat, lng: mapCenter.value.lng },
      deleted: false,
    });
    updateMarkerOrderValues();
  }
  else {
    showSnackMessage(t('location.too_many_markers', { marker: markerTypeName, max_count: maxMarkerCount }));
  }
}

function deleteItem(uid) {
  if (location.value?.markers) {
    const markerIndex = location.value?.markers.findIndex((marker) => marker.uid === uid);
    if (location.value?.markers[markerIndex]) {
      location.value.markers[markerIndex].deleted = true;
    }
  }
  updateMarkerOrderValues();
}

async function loadMarkerTypes() {
  try {
    const response = await axios.get('/api/locations/markers/types');
    markerTypes.value = response.data.data;
  }
  catch (error) {
    showSnackMessage(t('location.cannot_load_types'));
  }
}

function getColorFromMarkerType(markerType) {
  switch (markerType) {
    case 'meet':
      return '#02979d';
    case 'cart':
      return '#1859de';
    case 'rain':
      return '#7f18c0';
    case 'wc':
      return '#e62472';
    case 'store':
      return '#e9a014';
    default:
      return 'black';
  }
}

function markerImage(type) {
  if (type === 'store') {
    return '/images/mapmarkers/pin_store.png';
  }

  return `/images/mapmarkers/pin_${type}.png`;
}

loadMarkerTypes();
</script>
