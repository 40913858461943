<template>
  <div>
    <div v-if="asDialog">
      <v-btn
        :color="buttonColor"
        prepend-icon="fas fa-images"
        :text="t('images.title', 2)"
        class="float-right me-2"
        :class="smAndDown ? 'mt-2' : ''"
        @click="dialog = true"
      />
      <v-dialog
        v-model="dialog"
        max-width="800"
      >
        <v-carousel>
          <v-carousel-item
            v-for="(item, i) in images"
            :key="i"
            :src="item.href"
            cover
            @click="showImage(item)"
          />
        </v-carousel>
      </v-dialog>
    </div>
    <div v-else>
      <v-dialog v-model="dialog">
        <v-img :src="visibleItem?.href" />
      </v-dialog>
      <v-carousel>
        <v-carousel-item
          v-for="(item, i) in images"
          :key="i"
          :src="item.href"
          cover
          @click="showImage(item)"
        />
      </v-carousel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

const props = withDefaults(defineProps<{
  images?: Array<Partial<ImageForSlideShow>>;
  asDialog?: boolean;
  buttonColor?: string;
}>(), {
  images: () => [],
  asDialog: () => false,
  buttonColor: () => 'secondary',
});
const { smAndDown } = useDisplay();
const { t } = useI18n();
const dialog = ref(false);
const visibleItem = ref();

function showImage(item) {
  visibleItem.value = item;
  dialog.value = true;
}

interface ImageForSlideShow {
  href: string;
}
</script>
