<template>
  <v-responsive
    class="fill-height"
    min-height="300px"
  >
    <v-card
      class="text-center text-white d-flex flex-column fill-height"
      :image="heroImage"
    >
      <v-card-title
        v-if="status"
        class="text-white"
      >
        <div class="text-h5 text-white">
          <v-icon
            icon="fas fa-triangle-exclamation"
            class="text-warning"
          /> {{ status.title }}
        </div>
      </v-card-title>
      <v-card-text
        v-if="status"
        class="text-h6 text-white"
      >
        <div
          v-for="par, $index in (status.message?.split('\n') || [])"
          :key="$index"
        >
          {{ par }}
        </div>
      </v-card-text>
      <v-spacer />
      <v-card-actions>
        <v-row>
          <v-col class="ps-3 pe-0">
            <v-btn
              v-if="secondaryLink"
              :to="secondaryLink.to"
              variant="flat"
              elevation="2"
              color="primary"
              block
              size="large"
              :text="secondaryLink.caption"
            />
          </v-col>
          <v-col class="ps-1 pe-2 mx-1">
            <v-btn
              v-if="primaryLink"
              :to="primaryLink.to"
              variant="flat"
              color="secondary"
              elevation="2"
              size="large"
              block
              :text="primaryLink.caption"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-responsive>
</template>

<script setup lang="ts">
import { useAuth, usePermissions } from '@/composables';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  heroImage: string;
  profileImageStatus: string;
}>();

const { t } = useI18n();

const { authUser } = useAuth();
const { can } = usePermissions();

interface StatusType {
  title: string;
  message: string;
}

const status = computed<StatusType | false>(() => {
  switch (props.profileImageStatus) {
    case 'required':
      return {
        title: t('images.missing_profile'),
        message: t('images.missing_profile_message'),
      };
    case 'pending':
      return {
        title: t('images.under_review'),
        message: t('images.under_review_message'),
      };
    case 'rejected':
      return {
        title: t('images.rejected'),
        message: t('images.rejected_message'),
      };
    default: return false;
  }
});

interface LinkType {
  caption: string;
  to: string;
}

const primaryLink = computed<LinkType | null>(() => {
  if (['required', 'rejected'].includes(props.profileImageStatus)) {
    return {
      caption: t('global.view_profile'),
      to: '/mydetails',
    };
  }
  if (can('book-shifts')) {
    return {
      caption: t('smpw.submit_availability'),
      to: '/mycalendar',
    };
  }
  if (authUser.is_admin) {
    return {
      caption: t('global.administration'),
      to: '/admin',
    };
  }
  return null;
});

const secondaryLink = computed(() => {
  if (['required', 'rejected'].includes(props.profileImageStatus)) {
    return null;
  }
  if (can('book-shifts')) {
    return {
      caption: t('shift.manage'),
      to: '/myshifts',
    };
  }
  return {
    caption: t('global.my_details'),
    to: '/mydetails',
  };
});
</script>

<style scoped>
.bd-blur {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.status-box {
  background-color: rgba(0,0,0,0.5);
}
</style>
